module.exports = [{
      plugin: require('../node_modules/gatsby-theme-catalyst-core/node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/vercel/path0/node_modules/gatsby-remark-images","id":"c06e11bd-d726-5deb-beef-2794587576a8","name":"gatsby-remark-images","version":"3.10.0","pluginOptions":{"plugins":[],"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent","quality":50,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},"nodeAPIs":["pluginOptionsSchema"],"browserAPIs":["onRouteUpdate"],"ssrAPIs":[]}],"extensions":[".md",".mdx"],"defaultLayouts":{"default":"/vercel/path0/node_modules/gatsby-theme-catalyst-core/src/components/layout.js"},"gatsbyRemarkPlugins":[{"resolve":"gatsby-remark-relative-images","options":{}},{"resolve":"gatsby-remark-images","options":{"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent","quality":50}},{"resolve":"gatsby-remark-copy-linked-files","options":{"destinationDir":"content/site-assets"}},{"resolve":"gatsby-remark-smartypants"},{"resolve":"gatsby-remark-reading-time"},{"resolve":"gatsby-remark-responsive-iframe"},{"resolve":"gatsby-remark-external-links"}],"remarkPlugins":[null],"rehypePlugins":[],"lessBabel":false,"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":1440,"linkImagesToOriginal":false,"withWebp":true,"backgroundColor":"transparent","quality":50,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx-embed/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-theme-catalyst-core/gatsby-browser.js'),
      options: {"plugins":[],"displaySiteTitle":false,"displaySiteTitleMobile":false,"invertSiteLogo":false,"useStickyHeader":true,"useColorMode":false,"assetPath":"content/site-assets"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"unitedintonations","short_name":"unitedintonations","start_url":"/","background_color":"#4c91dc","theme_color":"#000000","display":"minimal-ui","icon":"content/site-assets/catalyst-site-icon.jpg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"48282fa3232338f6444f042240b9618d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-page-progress/gatsby-browser.js'),
      options: {"plugins":[],"includePaths":["/",{"regex":"^/news"}],"height":3,"prependToBody":false,"color":"#4c91dc","footerHeight":500},
    },{
      plugin: require('../node_modules/gatsby-plugin-netlify-cms/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
